import React from 'react';
import Section from '../_section';

export default () => (
  <Section className={'p-5 mt-2'}>
    <h4 className={'shadow p-2 border d-inline-block'}>Contact Us</h4>
    <p className={'lead'}>
      We value quality and we aim to be the best. Whether you have an opinion
      about care or a suggestion for improvement, we encourage your comments and
      want to hear from you. Our staff are always happy to answer any enquiries
      regarding our services and facilities and to provide you with further
      information and assistance.
    </p>
    <div className={'d-flex flex-column'}>
      <p className={'lead'}>General Enquiry</p>
      <p className={'lead'}>
        Tel: (852) 2537 6063 <br />
        Fax: (852) 2317 7171 <br />
        Email: cs@healthconcepts.com.hk
      </p>
      <p className={'lead'}>
        24 Hours Service Hotline <br />
        Tel: (852) 2317 7166
      </p>
      <div
        className={
          'd-flex flex-row justify-content-md-end justify-content-start align-items-center'
        }
        style={{ overflow: 'auto' }}
      >
        <img
          height={100}
          className={'mr-2'}
          src={require('../../assets/i11.jpg')}
        />
        <img
          height={100}
          className={'mx-2'}
          src={require('../../assets/i12.jpg')}
        />
        <img
          height={100}
          className={'mx-2'}
          src={require('../../assets/i13.jpg')}
        />
        <img
          height={100}
          className={'ml-2'}
          src={require('../../assets/i14.jpg')}
        />
      </div>
    </div>
  </Section>
);

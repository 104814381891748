import React from 'react';
import { Container } from 'reactstrap';
import P1 from './_p1';
import P2 from './_p2';
import P3 from './_p3';
import P4 from './_p4';
import P5 from './_p5';
import P6 from './_p6';
import P7 from './_p7';
import P8 from './_p8';

export default () => (
  <Container className={`py-2 text-light`} style={{ maxWidth: 720 }}>
    <P1 />
    <P2 />
    <P3 />
    <P4 />
    <P5 />
    <P6 />
    <P7 />
    <P8 />
  </Container>
);

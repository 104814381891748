import React from "react";
import { FormGroup, Label, Input, FormText } from "reactstrap";

export default ({
  value,
  name,
  placeholder = "--",
  options = [],
  description,
  required = true,
  onChange
}) => (
  <FormGroup className={'col-md-5 row'}>
    <Label>
      {name} {required && "*"}
    </Label>
    <Input
      value={value}
      type={"select"}
      required={required}
      onChange={onChange}
    >
      <option value={""}>{placeholder}</option>
      {options.map((opt, i) => (
        <option key={i} value={opt}>
          {opt}
        </option>
      ))}
    </Input>
    {/*<SingleSelector*/}
    {/*  name={name}*/}
    {/*  defaultValue={options[0]}*/}
    {/*  placeholderText={placeholder}*/}
    {/*  required={required}*/}
    {/*  onChange={onChange}*/}
    {/*  options={options.map(opt => ({ label: opt, value: opt }))}*/}
    {/*/>*/}
    <FormText>{description}</FormText>
  </FormGroup>
);

import React from 'react';
import { Form, FormGroup, Button } from 'reactstrap';
import { Formik } from 'formik';
import FormText from './_formText';
import FormSelect from './_formSelect';
import FormCheckbox from './_formCheckbox';
import FormTextarea from './_formTextarea';
import FormHTML from './_formHTML';
import Loading from '../loading';

export default ({ onSubmit, inputs = [], submitText = 'Submit' }) => (
  <Formik
    onSubmit={({ obj = {} } = {}, ...args) => onSubmit(obj, ...args)}
    initialValues={{
      obj: inputs.reduce(
        (reducer, { name, type, display }) =>
          display && type === 'CHECKBOX'
            ? { ...reducer, [name]: false }
            : reducer,
        {}
      )
    }}
  >
    {({
      values: { obj = {} } = {},
      handleSubmit,
      setFieldValue,
      isSubmitting
    }) => (
      <Form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        {inputs.map((input, i) => {
          const { name, type, display } = input;
          if (!display) return null;
          switch (type) {
            case 'TEXT':
              return (
                <FormText
                  {...input}
                  key={i}
                  value={obj[name]}
                  onChange={e => {
                    obj[name] = e.target.value;
                    setFieldValue('obj', obj);
                  }}
                />
              );
            case 'SELECT':
              return (
                <FormSelect
                  {...input}
                  key={i}
                  value={obj[name]}
                  onChange={e => {
                    obj[name] = e.target.value;
                    setFieldValue('obj', obj);
                  }}
                />
              );
            case 'CHECKBOX':
              return (
                <FormCheckbox
                  {...input}
                  key={i}
                  value={obj[name]}
                  onChange={e => {
                    obj[name] = e.target.checked;
                    setFieldValue('obj', obj);
                  }}
                />
              );
            case 'TEXTAREA':
              return (
                <FormTextarea
                  {...input}
                  key={i}
                  value={obj[name]}
                  onChange={e => {
                    obj[name] = e.target.value;
                    setFieldValue('obj', obj);
                  }}
                />
              );
            case 'HTML':
              return <FormHTML {...input} key={i} />;
            default:
              return null;
          }
        })}
        {isSubmitting ? (
          <Loading />
        ) : (
          <FormGroup className={'text-center mt-4'}>
            <Button type={'submit'} color={'primary'}>
              {submitText}
            </Button>
          </FormGroup>
        )}
      </Form>
    )}
  </Formik>
);

import React, { Component } from 'react';
import FormBuilder from '../../components/formBuilder';
import { auto2sc } from '../../shared/translate/tc2sc';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { client } from '../../shared/apollo';
import translate from '../../shared/translate';
import { errorParser } from '../../shared/errorParser';
import Success from './_success';

const SUBMIT = gql`
  mutation($input: PromotionRecordInput!) {
    promotionRecordCreate(input: $input) {
      id
    }
  }
`;

export default class extends Component {
  state = {
    createId: undefined,
    random: Math.random()
  };
  render() {
    const { customPageId, body = {} } = this.props;
    const { random, createId } = this.state;
    if (!!createId) return <Success id={createId} />;

    let { config = '{}' } = body;
    config = auto2sc(config);
    let {
      inputs = [],
      showTerms = true,
      terms = '',
      checkboxes = []
    } = JSON.parse(config);

    const sortedInputs = [
      ...inputs.sort((a, b) => a.sortIndex - b.sortIndex),
      { type: 'HTML', html: terms, display: showTerms },
      ...checkboxes.sort((a, b) => a.sortIndex - b.sortIndex)
    ];
    return (
      <FormBuilder
        key={random}
        submitText={translate['submit']}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const {
              data: { promotionRecordCreate }
            } = await client.mutate({
              mutation: SUBMIT,
              variables: {
                input: {
                  customPageId,
                  attributes: Object.keys(values)
                    .map(key => ({
                      key,
                      value: `${values[key]}`
                    }))
                    .sort((a, b) => {
                      let sortAIndex = sortedInputs.findIndex(
                        input => input.name === a.key
                      );
                      let sortBIndex = sortedInputs.findIndex(
                        input => input.name === b.key
                      );
                      return sortAIndex - sortBIndex;
                    })
                }
              }
            });
            toast.success(translate['submit_success']);
            this.setState({
              random: Math.random(),
              createId: promotionRecordCreate.id
            });
          } catch (e) {
            toast.error(errorParser(e));
          } finally {
            setSubmitting(false);
          }
        }}
        inputs={sortedInputs}
      />
    );
  }
}

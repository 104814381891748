import React from 'react';
import Section from '../_section';

export default () => (
  <Section className={'p-5 mt-2'}>
    <h4 className={'shadow p-2 border d-inline-block'}>
      Health Concepts Day Surgery and Endoscopy Centre
    </h4>
    <p className={'lead'}>Colonoscopy</p>
    <ul>
      <li className={'lead'}>
        With top-of-the-line HDTV endoscopy systems, colonoscopy helps identify
        the cause of gastrointestinal bleeding, chronic diarrhea, colon
        obstruction and anaemia.
      </li>
      <li className={'lead'}>
        It facilitates the diagnosis of disease in the colon like ulceration,
        inflammation, polyps, haemorrhoids, tumours, etc.
      </li>
      <li className={'lead'}>
        If needed, polyps will be removed and tissue samples will be obtained
        for biopsy test.
      </li>
      <li className={'lead'}>
        The procedure only requires approximately 30 minutes.
      </li>
    </ul>
    <p className={'lead'}>Gastroscopy</p>
    <ul>
      <li className={'lead'}>
        With top-of-the-line HDTV endoscopy systems, gastroscopy helps identify
        the cause of indigestion, gastritis, peptic ulceration, stomach
        bleeding, anaemia, swallowing problems, vomiting and abdominal
        discomfort.
      </li>
      <li className={'lead'}>
        It facilitates the diagnosis of disease in the digestive tract like
        ulceration, inflammation, tumours, cancer, etc.
      </li>
      <li className={'lead'}>
        If needed, polyps will be removed; tissue & mucous membrane samples will
        be obtained for Helicobacter Pylori Test or for the diagnosis of gastric
        ulcer/stomach cancer.
      </li>
      <li className={'lead'}>
        The procedure only requires approximately 10-15 minutes
      </li>
    </ul>
  </Section>
);

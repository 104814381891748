import React from "react";
import { FormGroup, FormText, Input, Label } from "reactstrap";

export default ({
  value,
  name,
  description,
  required = true,
  onChange,
  maxlength = 250
}) => (
  <FormGroup className={'col-md-5 row'}>
    <Label>
      {name} {required && "*"}
    </Label>
    <Input
      value={value}
      type={"textarea"}
      required={required}
      onChange={onChange}
      maxLength={maxlength}
    />
    <FormText>{description}</FormText>
  </FormGroup>
);

import React from 'react';
import Section from '../_section';

export default () => (
  <Section className={'p-5 mt-2'}>
    <div
      className={
        'd-flex flex-md-row flex-column justify-content-between align-items-md-end align-items-start'
      }
    >
      <h4 className={'shadow p-2 border d-inline-block'}>Clinic Locations</h4>
      <p className={'lead'}>* ISO 9001 accreditation</p>
    </div>
    <div className={'d-flex flex-md-row flex-column'}>
      <div className={'pr-md-2 d-flex flex-column'} style={{ flex: 1 }}>
        <div style={{ flexGrow: 1 }}>
          <p
            className={'font-italic font-weight-bold lead'}
            style={{ textDecoration: 'underline' }}
          >
            Hong Kong University of Science & Technology Health Centre*
          </p>
          <p className={'lead'}>
            Medical Clinic, LG1, <br />
            Hong Kong University of Science & Technology, Clearwater Bay, Hong
            Kong
          </p>
          <p className={'lead'}>
            Tel: (852) 2358 6670 <br />
            Fax: (852) 2358 2470 <br />
            Email: hkustclinic@healthconcepts.com.hk
          </p>
        </div>
        <div style={{ flexGrow: 1 }}>
          <p
            className={'font-italic font-weight-bold lead'}
            style={{ textDecoration: 'underline' }}
          >
            City University of Hong Kong - Young Chung Yee Health Centre*
          </p>
          <p className={'lead'}>
            4/F., Bank of China (Hong Kong) Complex, <br />
            City University of Hong Kong, <br />
            83 Tat Chee Avenue, Kowloon Tong, Hong Kong
          </p>
          <p className={'lead'}>
            Tel: (852) 3442 6066 <br />
            Fax: (852) 3442 0200 <br />
            Email: cityuclinic@healthconcepts.com.hk
          </p>
        </div>
      </div>
      <div className={'pl-md-2 d-flex flex-column'} style={{ flex: 1 }}>
        <div style={{ flexGrow: 1 }}>
          <p
            className={'font-italic font-weight-bold lead'}
            style={{ textDecoration: 'underline' }}
          >
            Health Concepts Day Surgery and Endoscopy Centre
          </p>
          <p className={'lead'}>
            Virtus Medical Tower, 122 Queen’s Road Central, Central, Hong Kong
          </p>
          <p>LG2, Nathan Square, 348 Nathan Road, Jordan, Kowloon</p>
          <p className={'lead'}>
            Tel: (852) 2537 6063 <br />
            Fax: (852) 2317 7171
          </p>
        </div>
        <div style={{ flexGrow: 1 }}>
          <p
            className={'font-italic font-weight-bold lead'}
            style={{ textDecoration: 'underline' }}
          >
            The Education University of Hong Kong Health Centre*
          </p>
          <p className={'lead'}>
            1/F., Cho Kwai Chee Foundation Building, EdUHK.,10 Lo Ping Road, Tai
            Po, The New Territories
          </p>
          <p className={'lead'}>
            Tel: (852) 2948 6262 <br />
            Fax: (852) 2948 6258 <br />
            Email: eduhkclinic@healthconcepts.com.hk
          </p>
        </div>
      </div>
    </div>
  </Section>
);

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import translate from '../../shared/translate';
import { Container } from 'reactstrap';
import Section from '../_section';

export default () => (
  <Fragment>
    <Container className={`container-fluid my-3 text-center`}>
      <Section className={'p-5'}>
        <p class='lead'>
          This website is only valid during the designated date(s)
        </p>
        <p class='lead'>本網站只於指定日期有效</p>
        <Link to={'/'} className={'mt-4 btn btn-outline-light'}>
          <span>{translate['back_to_home']}</span>
        </Link>
      </Section>
    </Container>
  </Fragment>
);

import React from 'react';
import Section from '../_section';

export default () => (
  <Section className={'p-5 mt-2'}>
    <h4 className={'shadow p-2 border d-inline-block'}>Our Services</h4>
    <p className={'lead'}>We provide the following services: </p>
    <ul>
      <li className={'lead'}>
        A panel doctor service comprising of over 170 experienced specialist and
        primary care service providers, with multi-disciplinary specialist
        support
      </li>
      <li className={'lead'}>
        A wide range of outpatient surgical and endoscopic services, namely
        gastroscopy and colonoscopy
      </li>
      <li className={'lead'}>
        Inpatient care through our panel at all major private hospitals
        throughout Hong Kong, Kowloon and the New Territories
      </li>
      <li className={'lead'}>
        24-hour emergency services and in-house call services (see below)
      </li>
      <li className={'lead'}>
        Health screening and assessment programs, including outpatient
        diagnostic work and laboratory tests
      </li>
    </ul>
    <div className={'shadow p-2 border'}>
      <p className={'lead'}>
        24-Hour Emergency Hotline & In-House Call Services{' '}
      </p>
      <p className={'lead m-0'}>
        We provide 24-hour emergency services with the provision of an on-call
        general practitioner home/hotel visit services. We also offer 24-hour
        emergency advisory hotline services that can be accessed both locally
        and internationally. All calls are answered by our professional clinical
        staff.
      </p>
    </div>
  </Section>
);

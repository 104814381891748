import React from 'react';

export default ({ children, className = '' }) => (
  <section
    className={`shadow rounded ${className}`}
    style={{
      backgroundImage: `url(${require('../assets/bg.jpg')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }}
  >
    {children}
  </section>
);

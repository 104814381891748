import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import HomePage from './homePage';
import PromotionView from './promotions/view';
import Error from './error';
import translate from '../shared/translate';
import ScrollTopButton from '../components/scrollTopButton';
import { Container } from 'reactstrap';

export default class extends Component {
  state = { scrollTopButtonVisible: false };
  handleScroll = e => {
    let scrollAmount = e.target.scrollTop;
    const { scrollTopButtonVisible } = this.state;
    if (scrollAmount >= 300 && !scrollTopButtonVisible) {
      this.setState({ scrollTopButtonVisible: true });
    } else if (scrollAmount < 300 && !!scrollTopButtonVisible) {
      this.setState({ scrollTopButtonVisible: false });
    }
  };

  render() {
    const {
      history: {
        location: { pathname }
      }
    } = this.props;
    const { scrollTopButtonVisible } = this.state;
    return (
      <div
        onScroll={this.handleScroll}
        className={'h-100 d-flex flex-column pb-5'}
        style={{ overflow: 'auto' }}
      >
        <div id={'top'} />
        <Helmet>
          <title>{translate['meta.main.title']}</title>
        </Helmet>
        <Switch>
          <Route path={'/'} component={HomePage} exact />
          <Route path={'/promotions/:href'} component={PromotionView} />
          <Route component={Error} />
        </Switch>
        <ScrollTopButton
          visiable={scrollTopButtonVisible}
          href={`${pathname}#top`}
        />
      </div>
    );
  }
}

import React from 'react';
import { Link } from 'react-router-dom';
import Section from '../_section';

export default ({}) => (
  <Section className={'d-flex flex-column p-5'}>
    <div
      className={
        'd-flex flex-md-row flex-column-reverse justify-content-end align-items-end'
      }
    >
      <div className={'mt-5'}>
        <img
          width={100}
          className={'mr-3'}
          src={require('../../assets/i2.jpg')}
        />
        <img width={100} src={require('../../assets/i1.jpg')} />
      </div>
      <Link to={'/'}>
        <img
          width={250}
          className={'mr-3'}
          src={require('../../assets/logo.png')}
        />
      </Link>
    </div>

    <div className={'d-flex my-5 flex-column align-items-end'}>
      <h1>HEALTH CONCEPTS LIMITED</h1>
      <h4>A member of Virtus Medical Group</h4>
    </div>
    <div
      className={'d-flex flex-row justify-content-md-end justify-content-start'}
      style={{
        overflow: 'auto'
      }}
    >
      <img
        height={100}
        className={'mr-2'}
        src={require('../../assets/i3.jpg')}
      />
      <img
        height={100}
        className={'mx-2'}
        src={require('../../assets/i4.jpg')}
      />
      <img
        height={100}
        className={'mx-2'}
        src={require('../../assets/i5.jpg')}
      />
      <img
        height={100}
        className={'ml-2'}
        src={require('../../assets/i6.jpg')}
      />
    </div>
  </Section>
);

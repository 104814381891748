import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { css } from 'glamor';
import Main from './routes';
import { Container } from 'reactstrap';
import Background from './assets/bg.svg';

export const strings = {};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexRoutes: [{ path: '/', component: Main }]
    };
  }

  render() {
    return (
      <div
        className={'text-light h-100'}
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: 'repeat',
          overflow: 'hidden'
        }}
      >
        <Router>
          <Switch>
            {this.state.indexRoutes.map((prop, key) => {
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
        </Router>
        <ToastContainer
          hideProgressBar={true}
          newestOnTop={true}
          toastClassName={css({
            borderRadius: '0.25rem'
          })}
        />
      </div>
    );
  }
}

export default App;

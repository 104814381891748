import React from 'react';
import translate from '../../shared/translate';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Container } from 'reactstrap';
import Loading from '../../components/loading';
import { auto2sc } from '../../shared/translate/tc2sc';

const GET_CUSTOM_PAGE = gql(`
  query ($href: String!, $groups: [String!]) {
    node:customPageByHref(otherFilter: { href: $href, groups: $groups }) {
      id
      name
      body {
        name
        config
      }
    }
  }
`);

const Check = () => (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='check-circle'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    className='svg-inline--fa fa-check-circle fa-w-16 fa-5x mb-2'
  >
    <path
      fill='currentColor'
      d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'
      className=''
    />
  </svg>
);

export default ({ id }) => (
  <Query
    query={GET_CUSTOM_PAGE}
    variables={{ href: 'promotion_success', groups: ['pages'] }}
  >
    {({ loading, data }) => {
      if (loading) return <Loading />;
      let { node } = data || {},
        { id, name, body: [{ config = '{}' }] = [{}] } = node || {};

      config = auto2sc(config);
      let { content = '' } = JSON.parse(config);

      content = content.replace(/\${referrence_number}/, id);

      return (
        <div
          className={`my-3 custom-container`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    }}
  </Query>
);

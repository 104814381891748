import React from 'react';
import Section from '../_section';

export default () => (
  <Section className={'p-5 mt-2'}>
    <h4 className={'shadow p-2 border d-inline-block'}>
      Health Concepts Day Surgery and Endoscopy Centre
    </h4>
    <p className={'lead'}>
      With top-of-the-line HDTV imaging systems and our professional team of
      specialists, Health Concepts Day Surgery and Endoscopy Centre delivers
      quality endoscopic services to patients in the most effective and cost
      efficient manner, with the aim of serving patients who do not need
      hospitalization; saving both time and cost.
    </p>
    <p className={'lead'}>
      All procedures are performed by our experienced panel of medical
      specialists. Utilising top-of-the-line HDTV imaging systems and
      comprehensive facilities, our well-trained nursing staff provide patients
      with the highest quality services in a warm and comfortable atmosphere.
    </p>
    <div
      className={'d-flex flex-row justify-content-md-end justify-content-start'}
      style={{
        overflow: 'auto'
      }}
    >
      <img
        height={80}
        className={'mr-2'}
        src={require('../../assets/i8.jpg')}
      />
      <img
        height={80}
        className={'mx-2'}
        src={require('../../assets/i9.jpg')}
      />
      <img
        height={80}
        className={'ml-2'}
        src={require('../../assets/i10.jpg')}
      />
    </div>
  </Section>
);

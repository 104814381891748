import React from 'react';
import Section from '../_section';

export default () => (
  <Section className={'p-5 mt-2'}>
    <h4 className={'shadow p-2 border d-inline-block'}>
      Welcome to Health Concepts Limited
    </h4>
    <p className={'lead'}>
      Health Concepts Limited is a healthcare service provider founded in 1997,
      operates a managed health network of over 170 general medical and
      specialist care service providers. We have been operating the staff and
      student clinic of The Hong Kong University of Science & Technology, The
      City University of Hong Kong and The Education University of Hong Kong. We
      also offer surgical treatments through The Health Concepts Day Surgery and
      Endoscopy Centre.{' '}
    </p>
    <p className={'lead'}>
      Health Concepts has been awarded as a Caring Company and we have achieved
      ISO 9001 accreditation since January 2007 and had upgraded to the newest
      version 9001:2015 in early 2017.
    </p>
    <p className={'lead'}>
      In January 2018, Health Concepts has partnered with the new premium
      medical provider - Virtus Medical Holdings Limited (“Virtus”). Virtus is a
      new premium private medical provider built on a unique doctor partnership
      model, the result of a merger of the medical practices from 40 doctors,
      coupled with financial backing from international and sovereign funds
      (Samena Capital and Cenova Capital).
    </p>
  </Section>
);

import React from 'react';

export default ({ href, onClick, visiable = false, color = 'light' }) =>
  !!visiable && (
    <a
      href={href}
      className={`btn btn-${color}`}
      onClick={onClick}
      style={{
        position: 'absolute',
        bottom: 10,
        right: 10
      }}
    >
      <i
        className={'fa fa-arrow-up'}
        style={{
          color: '#309ace'
        }}
      />
    </a>
  );

import React from 'react';
import Section from '../_section';

export default () => (
  <Section className={'p-5 mt-2'}>
    <h4 className={'shadow p-2 border d-inline-block'}>
      We Are Committed To...
    </h4>
    <ul>
      <li className={'lead'}>
        Improving all aspects of your health with our resources
      </li>
      <li className={'lead'}>
        Offering up-to-date medical therapy rendered in a caring manner
      </li>
      <li className={'lead'}>
        Promoting your well-being with an awareness of corporate and individual
        needs
      </li>
      <li className={'lead'}>Learning from and improving with your feedback</li>
    </ul>
    <h4 className={'shadow p-2 border d-inline-block'}>We Offer...</h4>
    <ul>
      <li className={'lead'}>
        High-quality, cost-effective healthcare services in a professional and
        comfortable environment
      </li>
      <li className={'lead'}>
        Medical clinics with an emphasis on comfort, convenience and privacy
      </li>
      <li className={'lead'}>
        Information to enable you to make decisions about your healthcare and
        lifestyle
      </li>
      <li className={'lead'}>
        Consideration and dignity in all interactions with us
      </li>
    </ul>
  </Section>
);

import React, { Fragment, Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Loading from '../../components/loading';
import { Container } from 'reactstrap';
import CustomContent from './_customContent';
import CustomForm from './_customForm';
import translate from '../../shared/translate';
import { Helmet } from 'react-helmet';
import { auto2sc } from '../../shared/translate/tc2sc';
import Error from '../error';
import Section from '../_section';

const GET_QUERY = gql`
  query($href: String!, $groups: [String!]) {
    node: customPageByHref(otherFilter: { href: $href, groups: $groups }) {
      id
      name
      group
      body {
        name
        config
      }
    }
  }
`;

export default ({
  history,
  match: {
    params: { href }
  }
}) => (
  <Container className={`my-3`}>
    <Query query={GET_QUERY} variables={{ href, groups: ['hc_promotion'] }}>
      {({ loading, data }) => {
        if (loading) return <Loading />;
        try {
          const { node } = data || {};
          const { id, name, body = [] } = node;
          return (
            <Section className={'p-5'}>
              <Helmet>
                <title>
                  {auto2sc(name)} - {translate['meta.main.title']}
                </title>
              </Helmet>
              <CustomContent body={body[0]} />
              <div className={'custom-container'}>
                <CustomForm customPageId={id} body={body[1]} />
              </div>
            </Section>
          );
        } catch (e) {
          return <Error history={history} />;
        }
      }}
    </Query>
  </Container>
);

import LocalizedStrings from 'react-localization';
import { cookies } from '../cookies';

const Strings = new LocalizedStrings({
  'zh-HK': require('./zh-HK.json'),
  'zh-CN': require('./zh-CN.json')
});
const defaultLang = cookies.get('lang');
defaultLang && Strings.setLanguage(defaultLang);

export default Strings;
